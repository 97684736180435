import { GLOBAL } from 'saddlebag-browser';
import { SingleValueCookie } from 'saddlebag-cookie';

const $window = GLOBAL.getWindow();

const isLocalhost = () =>
  ['localhost', '127.0.0.1', ''].includes($window.location.hostname);

const CASH_BACK_USER_ID = 'cbuserid';
const EXPIRES_DAYS = 1;

const storeCashBackUserId = (cashBackUserId: any) => {
  if (!cashBackUserId || cashBackUserId === 'undefined') {
    return;
  }
  const cookie = new SingleValueCookie(CASH_BACK_USER_ID);
  cookie.setValue(cashBackUserId);
  if (isLocalhost()) {
    cookie.store(EXPIRES_DAYS, { secure: false, path: '/' }); // NOSONAR
  } else {
    cookie.store(EXPIRES_DAYS);
  }
};

const getCashBackUserId = () =>
  new SingleValueCookie(CASH_BACK_USER_ID).getValue();

export { getCashBackUserId, storeCashBackUserId };
