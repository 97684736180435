/* eslint-disable no-underscore-dangle */
import { GLOBAL } from 'saddlebag-browser';
import { awaitComponent } from 'saddlebag-componentregistry';

const $window = GLOBAL.getWindow();
const { strevdaRuntimeProps, strevdaRuntimeVersion } = $window.__internal;
delete $window.__internal.strevdaRuntimeProps;
delete $window.__internal.strevdaRuntimeVersion;

export default () =>
  awaitComponent(
    {
      name: 'strevda-runtime',
      version: strevdaRuntimeVersion.trim(),
    },
    ({ hydrate, props }) => {
      hydrate({ ...props, ...strevdaRuntimeProps });
    },
  );
