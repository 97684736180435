import observer from 'saddlebag-observer';

import { sendToNewRelic } from './utils';

export const sendYoloScriptDisplayedCustomAttribute = (
  value: string,
  windowObject: Record<string, any>,
) => sendToNewRelic('yoloScriptDisplayed', value, windowObject);

export default (windowObject: Record<string, any>) => {
  sendToNewRelic('viewportWidth', windowObject.innerWidth, windowObject);
  sendToNewRelic('viewportHeight', windowObject.innerHeight, windowObject);

  /* eslint-disable no-underscore-dangle */
  const vertical =
    windowObject.__internal.trackingVertical ||
    windowObject.__internal.vertical;
  /* eslint-enable no-underscore-dangle */
  sendToNewRelic('vertical', vertical, windowObject);
  observer.subscribe('sid-yolo-display-success', () =>
    sendYoloScriptDisplayedCustomAttribute('success', windowObject),
  );
  observer.subscribe('sid-yolo-display-failed', () =>
    sendYoloScriptDisplayedCustomAttribute('fail', windowObject),
  );
};
