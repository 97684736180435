import type { StringTranslations } from 'common-types/types/components';

export const namespace = 'falcon';
export const SEO_LANDING_PAGE_EVENT = 'seo_landing_page_event';

// This array must match the PageType enum in the landing_pages.proto schema
// https://github.skyscannertools.net/data-management-services/schemas/blob/0c24b979b567f1cc3acd36bfd8adaeec4ccdbd1e/landing_pages.proto#L20-L40
export const MINI_EVENT_PAGE_TYPE = [
  'UNSET_PAGE_TYPE',
  'ARRIVALS_DEPARTURES',
  'FLIGHTS_AIRLINE',
  'FLIGHTS_CITY_AIRPORTS',
  'FLIGHTS_FROM_CITY_AIRPORT',
  'FLIGHTS_FROM_COUNTRY',
  'FLIGHTS_HOME',
  'FLIGHTS_TO_CITY_AIRPORT',
  'FLIGHTS_TO_COUNTRY',
  'LAST_MINUTE_DEALS',
  'ROUTES_FROM_TO',
  'ROUTES_FROM_CITY_AIRPORT_TO_COUNTRY',
  'ROUTES_FROM_COUNTRIES_TO_COUNTRY',
  'ROUTES_FROM_COUNTRY_TO_COUNTRY',
  'AIRLINE_FLIGHTS_TO_CITY',
  'AIRLINE_FLIGHTS_TO_AIRPORT',
  'CAR_HIRE_AIRPORT',
  'CAR_HIRE_COUNTRY',
  'CAR_HIRE_FAQ',
  'CAR_HIRE_HOME',
  'CAR_HIRE_LOCATION',
  'CAR_HIRE_PROVIDER',
  'CAR_HIRE_NEIGHBOURHOOD',
  'CAR_HIRE_WITH_PROVIDER',
  'CAR_HIRE_TRAIN_STATION',
  'CAR_HIRE_REGION',
  'CAR_HIRE_PROVIDER_IN_CITY',
  'CAR_HIRE_PROVIDER_AT_AIRPORT',
  'CAR_HIRE_PROVIDER_IN_COUNTRY',
  'CAR_HIRE_CAR_TYPE',
  'CAR_HIRE_ONE_WAY',
  'HOTELS_HOME',
  'HOTELS_NEAR_PLACE',
];

export const EXPERIMENT_CHOKE_POINT_SCHEMA_NAME = 'experiment_choke_point';
export const EXPERIMENT_CHOKE_POINT_MINIEVENT_NAME = 'ExperimentChokePoint';

export const COMPONENT_NAME = {
  ACCORDION: 'ACCORDION',
  UNSET_SOURCE_COMPONENT: 'UNSET_SOURCE_COMPONENT',
  EXPANDABLE_TEXT: 'EXPANDABLE_TEXT',
  INFO_BLOCK_FREEFORM: 'INFO_BLOCK_FREEFORM',
  CAR_HIRE_SEARCH_CONTROLS: 'CAR_HIRE_SEARCH_CONTROLS',
  CAR_HIRE_INDICATIVE_DEALS: 'CAR_HIRE_INDICATIVE_DEALS',
  DYNAMIC_MAP: 'DYNAMIC_MAP',
  PROVIDER_CARD: 'PROVIDER_CARD',
  CAR_HIRE_PROVIDER_LOGOS: 'CAR_HIRE_PROVIDER_LOGOS',
  CAR_HIRE_CONFIDENCE_PANELS: 'CAR_HIRE_CONFIDENCE_PANELS',
  ROUTE_FAQS: 'ROUTE_FAQS',
  FAQS: 'FAQS',
  FAQS_FROM_CONTENT_API_POC: 'FAQS_FROM_CONTENT_API_POC',
  INTERNAL_LINKS: 'INTERNAL_LINKS',
  SEO_CROSS_LINK: 'SEO_CROSS_LINK',
  SEO_BANNER: 'SEO_BANNER',
  CONFIDENCE_MESSAGING: 'CONFIDENCE_MESSAGING',
  CONTENT_BLOCK: 'CONTENT_BLOCK',
  PROVIDER_LOGOS: 'PROVIDER_LOGOS',
  APP_BANNER: 'APP_BANNER',
  BREADCRUMBS: 'BREADCRUMBS',
  INDEX_BLOCK_PANEL: 'INDEX_BLOCK_PANEL',
  POPULAR_DESTINATIONS: 'POPULAR_DESTINATIONS',
  PROVIDER_RATING: 'PROVIDER_RATING',
  PROVIDER_RATING_BIG: 'PROVIDER_RATING_BIG',
  INSPIRATION_PANELS: 'INSPIRATION_PANELS',
  HORIZONTAL_NAVIGATION: 'HORIZONTAL_NAVIGATION',
  INFO_SNIPPET: 'INFO_SNIPPET',
  FOOTER: 'FOOTER',
  NO_DEALS: 'NO_DEALS',
  STAYCATION: 'STAYCATION',
  CITY_BREAKS: 'CITY_BREAKS',
  HOTEL_SEARCH_CONTROLS: 'HOTEL_SEARCH_CONTROLS',
  HOTEL_AUTOSUGGEST: 'HOTEL_AUTOSUGGEST',
  DESTINATION_INFORMATION: 'DESTINATION_INFORMATION',
  CHEAPEST_TIME_TO: 'CHEAPEST_TIME_TO',
  HOTEL_SEARCH_RESULTS: 'HOTEL_SEARCH_RESULTS',
  DOWNTOWN_POPUP: 'DOWNTOWN_POPUP',
  HOTEL_BY_STAR_RATING: 'HOTEL_BY_STAR_RATING',
  IMAGE_SNIPPET: 'IMAGE_SNIPPET',
  IMAGE_SNIPPET_LIST: 'IMAGE_SNIPPET_LIST',
};

const constants: StringTranslations = {
  'arrivals-departures': 'ARRIVALS_DEPARTURES',
  'flights-airline': 'FLIGHTS_AIRLINE',
  'flights-city-airports': 'FLIGHTS_CITY_AIRPORTS',
  'flights-from-city-airport': 'FLIGHTS_FROM_CITY_AIRPORT',
  'flights-from-country': 'FLIGHTS_FROM_COUNTRY',
  'flights-to-city-airport': 'FLIGHTS_TO_CITY_AIRPORT',
  'flights-to-country': 'FLIGHTS_TO_COUNTRY',
  'last-minute-deals': 'LAST_MINUTE_DEALS',
  'routes-from-to': 'ROUTES_FROM_TO',
  'routes-from-city-airport-to-country': 'ROUTES_FROM_CITY_AIRPORT_TO_COUNTRY',
  'routes-from-countries-to-country': 'ROUTES_FROM_COUNTRIES_TO_COUNTRY',
  'routes-from-country-to-country': 'ROUTES_FROM_COUNTRY_TO_COUNTRY',
  'airline-flights-to-city': 'AIRLINE_FLIGHTS_TO_CITY',
  UNSET_PLATFORM: 'UNSET_PLATFORM',
  namespace,
  AIRPORT: 'airport',
  CITY: 'city',
  NATION: 'nation',
  DIRECT: 'DIRECT',
  INDIRECT: 'INDIRECT',
  UNKNOWN: 'UNKNOWN',
  LOW: 'LOW',
  MODERATE: 'MODERATE',
  MAJOR: 'MAJOR',
  FLIGHTS_AIRLINE: 'flights-airline',
  FLIGHTS_TO_CITY_AIRPORT: 'flights-to-city-airport',
  FLIGHTS_TO_COUNTRY: 'flights-to-country',
  FLIGHTS_TO_REGION: 'flights-to-region',
  locationAttributeEncoding: 'SS_GEO1_ID',
};

export const FALCON_EVENT = {
  EVENT_NAME: 'FalconLandingPageAction',
  SCHEMA_NAME: 'falcon_landing_pages',
};

export const COMPONENT_ACTION = {
  ACCORDION: {
    ACCORDION_CLICKED: 'ACCORDION_CLICKED',
    ACCORDION_URL_CLICKED: 'ACCORDION_URL_CLICKED',
  },
  CAR_HIRE_SEARCH_CONTROLS: {
    PICK_UP_LOCATION_CLICKED: 'PICK_UP_LOCATION_CLICKED',
    DROP_OFF_LOCATION_CLICKED: 'DROP_OFF_LOCATION_CLICKED',
    PICK_UP_DATE_CLICKED: 'PICK_UP_DATE_CLICKED',
    PICK_UP_TIME_CLICKED: 'PICK_UP_TIME_CLICKED',
    DROP_OFF_DATE_CLICKED: 'DROP_OFF_DATE_CLICKED',
    DROP_OFF_TIME_CLICKED: 'DROP_OFF_TIME_CLICKED',
    DIFFERENT_DROP_OFF_CLICKED: 'DIFFERENT_DROP_OFF_CLICKED',
    AGE_BETWEEN_CLICKED: 'AGE_BETWEEN_CLICKED',
    DRIVER_AGE_CLICKED: 'DRIVER_AGE_CLICKED',
    SEARCH_BUTTON_CLICKED: 'SEARCH_BUTTON_CLICKED',
  },
  CAR_HIRE_INDICATIVE_DEALS: {
    DEAL_CLICKED: 'DEAL_CLICKED',
    SHOW_MORE_BUTTON_CLICKED: 'SHOW_MORE_BUTTON_CLICKED',
    VIEW_ALL_DEALS_BUTTON: 'VIEW_ALL_DEALS_BUTTON',
    PREV_CLICKED: 'PREV_CLICKED',
    NEXT_CLICKED: 'NEXT_CLICKED',
  },
  DYNAMIC_MAP: {
    MAP_MARKER_CLICKED: 'MAP_MARKER_CLICKED',
    MAP_CTA_CLICKED: 'MAP_CTA_CLICKED',
  },
  CAR_HIRE_PROVIDER_LOGOS: {
    SUPPLIER_LOGO_CLICKED: 'SUPPLIER_LOGO_CLICKED',
  },
  PROVIDER_LOGOS: {
    SUPPLIER_LOGO_CLICKED: 'SUPPLIER_LOGO_CLICKED',
  },
  EXPANDABLE_TEXT: {
    READ_MORE_LESS_CLICKED: 'READ_MORE_LESS_CLICKED',
  },
  INFO_BLOCK_FREEFORM: {
    READ_MORE_LESS_CLICKED: 'READ_MORE_LESS_CLICKED',
  },
  INFO_SNIPPET: {
    INFO_SNIPPET_CLICKED: 'INFO_SNIPPET_CLICKED',
  },
  ROUTE_FAQS: {
    FAQ_CLICKED: 'FAQ_CLICKED',
    FAQ_URL_CLICKED: 'FAQ_URL_CLICKED',
  },
  FAQS: {
    FAQ_CLICKED: 'FAQ_CLICKED',
    FAQ_URL_CLICKED: 'FAQ_URL_CLICKED',
  },
  CONTENT_BLOCK: {
    CONTENT_URL_CLICKED: 'CONTENT_URL_CLICKED',
  },
  INTERNAL_LINKS: {
    INTERNAL_LINK_CLICKED: 'INTERNAL_LINK_CLICKED',
  },
  SEO_CROSS_LINK: {
    SEO_CROSS_LINK_CLICKED: 'SEO_CROSS_LINK_CLICKED',
  },
  SEO_BANNER: {
    SEO_BANNER_CLICKED: 'SEO_BANNER_CLICKED',
  },
  APP_BANNER: {
    APP_BANNER_CLICKED: 'APP_BANNER_CLICKED',
  },
  BREADCRUMBS: {
    BREADCRUMBS_CLICKED: 'BREADCRUMBS_CLICKED',
  },
  POPULAR_DESTINATIONS: {
    POPULAR_DESTINATIONS_CLICKED: 'POPULAR_DESTINATIONS_CLICKED',
    POPULAR_DESTINATIONS_PREV_CLICKED: 'POPULAR_DESTINATIONS_PREV_CLICKED',
    POPULAR_DESTINATIONS_NEXT_CLICKED: 'POPULAR_DESTINATIONS_NEXT_CLICKED',
  },
  PROVIDER_RATING: {
    PROVIDER_RATING_CLICKED: 'PROVIDER_RATING_CLICKED',
    PROVIDER_RATING_PREV_CLICKED: 'PROVIDER_RATING_PREV_CLICKED',
    PROVIDER_RATING_NEXT_CLICKED: 'PROVIDER_RATING_NEXT_CLICKED',
  },
  HORIZONTAL_NAVIGATION: {
    HORIZONTAL_NAVIGATION_CLICKED: 'HORIZONTAL_NAVIGATION_CLICKED',
    HORIZONTAL_NAVIGATION_BACK_TO_TOP: 'HORIZONTAL_NAVIGATION_BACK_TO_TOP', // deprecate, The functionality of buttons has been expanded
    HORIZONTAL_NAVIGATION_BUTTON_CLICKED:
      'HORIZONTAL_NAVIGATION_BUTTON_CLICKED',
  },
  NO_DEALS: {
    NO_DEALS_CTA_CLICKED: 'NO_DEALS_CTA_CLICKED',
  },
  STAYCATION: {
    HRW_CITY_PILL_CLICKED: 'HRW_CITY_PILL_CLICKED',
    CARD_CLICKED: 'CARD_CLICKED',
    HRW_ARROW_CLICKED: 'HRW_ARROW_CLICKED',
  },
  CITY_BREAKS: {
    CARD_CLICKED: 'CARD_CLICKED',
    HRW_ARROW_CLICKED: 'HRW_ARROW_CLICKED',
  },
  HOTEL_SEARCH_CONTROLS: {
    AUTO_SUGGEST_POIS_RENDER: 'AUTO_SUGGEST_POIS_RENDER',
    AUTO_SUGGEST_POIS_CLICK: 'AUTO_SUGGEST_POIS_CLICK',
    HOME_PAGE_SEARCH: 'HOME_PAGE_SEARCH',
  },
  HOTEL_SEARCH_RESULTS: {
    CARD_CLICKED: 'CARD_CLICKED',
    SEO_BANNER_CLICKED: 'SEO_BANNER_CLICKED',
    MORE_BUTTON_CLICKED: 'MORE_BUTTON_CLICKED',
  },
  HOTEL_AUTOSUGGEST: {
    CONTROLS_ACTIVATED: 'CONTROLS_ACTIVATED',
    RESULTS_UPDATED: 'RESULTS_UPDATED',
    RESULT_SELECTED: 'RESULT_SELECTED',
    CONTROLS_DISMISSED: 'CONTROLS_DISMISSED',
    AUTOSUGGEST_SEARCH: 'AUTOSUGGEST_SEARCH',
  },
  PROVIDER_CARD: {
    REDIRECT_LINK_CLICKED: 'REDIRECT_LINK_CLICKED',
  },
  DOWNTOWN_POPUP: {
    REDIRECT_LINK_CLICKED: 'REDIRECT_LINK_CLICKED',
  },
  CHEAPEST_TIME_TO: {
    LINK_CLICKED: 'LINK_CLICKED',
    SHOW_MORE_CLICKED: 'SHOW_MORE_CLICKED',
  },
  IMAGE_SNIPPET: {
    REDIRECT_LINK_CLICKED: 'REDIRECT_LINK_CLICKED',
  },
  IMAGE_SNIPPET_LIST: {
    IMAGE_SNIPPET_LIST_PREV_CLICKED: 'IMAGE_SNIPPET_LIST_PREV_CLICKED',
    IMAGE_SNIPPET_LIST_NEXT_CLICKED: 'IMAGE_SNIPPET_LIST_NEXT_CLICKED',
  },
};

export const ACTION_TYPE = {
  UNSET_ACTION_TYPE: 'UNSET_ACTION_TYPE',
  PAGE_LOADED: 'PAGE_LOADED',
  COMPONENT_LOADED: 'COMPONENT_LOADED',
  COMPONENT_CLICKED: 'COMPONENT_CLICKED',
  COMPONENT_VISIBLE: 'COMPONENT_VISIBLE',
  CONTENT_VISIBLE: 'CONTENT_VISIBLE',
};

export const LOAD_STATUS = {
  UNSET_LOAD_STATUS: 'UNSET_LOAD_STATUS',
  LOADED: 'LOADED',
  INVALID: 'INVALID',
};

export const CAR_TYPE = {
  SUBCLASS_TWO_TO_THREE_DOOR: 'TWO_TO_THREE_DOOR',
  SUBCLASS_TWO_OR_FOUR_DOOR: 'TWO_TO_FOUR_DOOR',
  SUBCLASS_FOUR_TO_FIVE_DOOR: 'FOUR_TO_FIVE_DOOR',
  SUBCLASS_WAGON_ESTATE: 'WAGON_ESTATE',
  SUBCLASS_PASSENGER_VAN: 'PASSENGER_VAN',
  SUBCLASS_LIMOUSINE: 'LIMOUSINE',
  SUBCLASS_SPORT_CAR: 'SPORT',
  SUBCLASS_CONVERTIBLE: 'CONVERTIBLE',
  SUBCLASS_SUV: 'SUV',
  SUBCLASS_OPEN_AIR_ALL_TERRAIN: 'ALL_TERRAIN',
  SUBCLASS_SPECIAL: 'SPECIAL_TYPE',
  SUBCLASS_PICKUP_REGULAR_CAB: 'PICK_UP_REGULAR',
  SUBCLASS_PICKUP_EXTENDED_CAB: 'PICK_UP_EXTENDED',
  SUBCLASS_SPECIAL_OFFER_CAR: 'SPECIAL_OFFER',
  SUBCLASS_COUPE: 'COUPE',
  SUBCLASS_MONOSPACE: 'MONOSPACE',
  SUBCLASS_RECREATIONAL: 'RECREATIONAL',
  SUBCLASS_MOTOR_HOME: 'MOTOR_HOME',
  SUBCLASS_TWO_WHEEL_VEHICLE: 'TWO_WHEEL',
  SUBCLASS_ROADSTER: 'ROADSTER',
  SUBCLASS_CROSSOVER: 'CROSSOVER',
  SUBCLASS_COMMERCIAL_VAN_OR_TRUCK: 'COMMERCIAL_VAN',
};

export const HOTELS_PRICE_TYPE = {
  PRICE_TYPE_PER_NIGHT: 'price-per-night',
};

export const HOTEL_BOOKING_LIMITS = {
  MAX_STAY_LENGTH: 30,
  MAX_DAYS_IN_ADVANCE: 564,
  MAX_ADULTS: 10,
  MIN_ADULTS: 1,
  DEFAULT_ADULTS: 2,
  MAX_ROOMS: 5,
  MIN_ROOMS: 1,
  DEFAULT_ROOMS: 1,
  MAX_CHILDREN: 5,
  MIN_CHILDREN: 0,
  MAX_CHILD_AGE: 18,
  MIN_CHILD_AGE: 0,
};

export const PRODUCT_GROUP = {
  FLIGHTS: 'flights',
  HOTELS: 'hotels',
  CAR_HIRE: 'car-hire',
} as const;

export default constants;
