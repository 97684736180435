import queryString from 'query-string';

const getSearch = (url: string) => (url ? url.split('?')[1] : '');

export const getQueryParam = (url: string, parameter: string) => {
  const search = getSearch(url);
  const parsed = queryString.parse(search);
  const value = parsed[parameter];
  return value || null;
};

export const getIntParam = (url: string, name: string) => {
  const val = getQueryParam(url, name);
  if (!val) {
    return null;
  }

  const number = parseInt(val as string, 10);
  return isNaN(number) ? null : number; // eslint-disable-line no-restricted-globals
};
