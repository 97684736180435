/* eslint-disable no-underscore-dangle */
import { GLOBAL } from 'saddlebag-browser';
import { awaitComponent } from 'saddlebag-componentregistry';

const $window = GLOBAL.getWindow();
const { headerVersion } = $window.__internal;

export default () =>
  awaitComponent(
    {
      name: 'header',
      version: headerVersion,
    },
    ({ hydrate, props }) => {
      hydrate(props);
    },
  );
