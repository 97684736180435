/* eslint-disable no-underscore-dangle */
import ReactDOM from 'react-dom';

import { GLOBAL } from 'saddlebag-browser';
import { awaitComponent } from 'saddlebag-componentregistry';
import logger from 'saddlebag-logger';

import { namespace } from './constants';

const $window = GLOBAL.getWindow();
const doc = GLOBAL.getDocument();
const { pageVariant, tagManagerProps, tagManagerVersion } = $window.__internal;
const { pageType } = tagManagerProps;
delete $window.__internal.tagManagerProps;
delete $window.__internal.tagManagerVersion;

export default () =>
  awaitComponent(
    {
      name: 'js-tag-manager',
      version: tagManagerVersion,
    },
    ({ componentDefinition: Component, props }) => {
      const renderProps = Object.assign(props, tagManagerProps);
      ReactDOM.render(
        <Component {...renderProps} />,
        doc.getElementById('tag-manager-react'),
      );
      logger.logOperationalEvent({
        eventName: 'load-jstm',
        pageSection: pageType,
        serviceName: namespace,
        pageVariant,
      });
    },
  );
