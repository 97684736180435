// __webpack_public_path__ needs to be set in own file
// see https://github.com/webpack/webpack/issues/2776

let publicPath;
try {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    publicPath = window.__internal.publicPath;
  }
} catch (e) {
  fetch('https://slipstream.skyscanner.net/grp/v1/applog/public/falcon', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify({
      message: `Error in webpack bundle bootstrapping: ${e.message}`,
      fields: {
        url: window.location.href,
        error: e.stack,
      },
    }),
  });
  throw e;
}

// eslint-disable-next-line camelcase, no-undef
__webpack_public_path__ = publicPath;
