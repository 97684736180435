import { GLOBAL } from 'saddlebag-browser';

const $window = GLOBAL.getWindow();
const $document = GLOBAL.getDocument();
const FIXED_SPACING = 20;

const getAcceptLanguageFirstLang = (
  acceptLanguage: string | null,
): string | null => {
  if (!acceptLanguage) {
    return null;
  }

  return acceptLanguage.split(',')[0].split(';')[0];
};

const anchorScroll = (scrollId: string | undefined) => {
  const target = $document.getElementById(scrollId);
  if (target) {
    const navBar = $document.getElementById('HorizontalNavigation');
    const top = navBar
      ? target.offsetTop - navBar.offsetHeight - FIXED_SPACING
      : target.offsetTop - FIXED_SPACING;
    $window.scrollTo({
      top,
      behavior: 'smooth',
    });
  }
};

const sendToNewRelic = (
  attributeName: string,
  attributeValue: string,
  windowObject: Record<string, any>,
): void => {
  const { newrelic } = windowObject;
  if (newrelic) {
    newrelic.setCustomAttribute(attributeName, attributeValue);
  }
};

// e.g. car-hire-location to CAR_HIRE_LOCATION
const parsePageType = (pageType: string): string => {
  if (!pageType) return '';

  return pageType.replace(/-/g, '_').toUpperCase();
};

export {
  anchorScroll,
  getAcceptLanguageFirstLang,
  sendToNewRelic,
  parsePageType,
};
