/* eslint-disable no-underscore-dangle */
import { initTagManager, sendEvent } from '@skyscanner-internal/gtm-client';
import { differenceInMilliseconds } from 'date-fns/differenceInMilliseconds';
import { GLOBAL } from 'saddlebag-browser';
import logger from 'saddlebag-logger';

import { namespace, SEO_LANDING_PAGE_EVENT } from './constants';

const loadGtmClient = () => {
  const $window = GLOBAL.getWindow();
  const startTime = Date.now();
  const { gtmProps, pageVariant } = $window.__internal;
  const { gtmIsEnabled, pageType, vertical } = gtmProps;

  if (gtmIsEnabled) {
    logger.logOperationalEvent({
      eventName: 'load-gtm-client',
      status: 'start',
      pageSection: pageType,
      verticalType: vertical,
      executionTime: differenceInMilliseconds(Date.now(), startTime),
      pageVariant,
    });

    try {
      initTagManager(gtmProps.market);
      sendEvent({ event: SEO_LANDING_PAGE_EVENT, ...gtmProps });
      logger.logOperationalEvent({
        eventName: 'load-gtm-client',
        status: 'success',
        pageSection: pageType,
        verticalType: vertical,
        executionTime: differenceInMilliseconds(Date.now(), startTime),
        pageVariant,
      });
    } catch (error: any) {
      logger.logOperationalEvent({
        eventName: 'load-gtm-client',
        status: 'failed',
        pageSection: pageType,
        verticalType: vertical,
        executionTime: differenceInMilliseconds(Date.now(), startTime),
        pageVariant,
      });
      logger.logError(error, {
        message: `Failed to load gtmClient on ${namespace}: ${error.message}`,
        component: namespace,
      });
    }
  }
};

export default loadGtmClient;
