/* eslint-disable no-underscore-dangle */
import Cookies from 'js-cookie';
import { GLOBAL } from 'saddlebag-browser';

const $window = GLOBAL.getWindow();

const { currency, locale, market } = $window.__internal.culture;

export default () => {
  if (!Cookies.get('ssculture')) {
    const value = `locale:::${locale}&market:::${market}&currency:::${currency}`;
    Cookies.set('ssculture', value, 7);
  }
};
