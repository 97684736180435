const CARHIR_AIRPORT_INDICATIVE_PRICE_PANEL_EVENT =
  'trackable-carhire-airport-indicative-price-panel-clicked-event';
const SEARCH_CLICKED_PAGETYPE_EVENT = 'trackable-search-clicked-pagetype-event';
const CARHIRE_SEO_METRIC = 'trackable-carhire-seo-metric-event';

const SCHEMA_EVENTS_MAP = {
  [CARHIR_AIRPORT_INDICATIVE_PRICE_PANEL_EVENT]:
    'car_hire.CarhireAirportIndicativePricePanel',
  [SEARCH_CLICKED_PAGETYPE_EVENT]: 'car_hire.CarHireSearchButton',
  [CARHIRE_SEO_METRIC]: 'car_hire.CarHireSeoEvent',
};

class FunnelEventsListener {
  observer;

  funnelLogger;

  constructor(funnelLogger: any, observer: any) {
    this.observer = observer;
    this.funnelLogger = funnelLogger;
  }

  subscribeToFunnelEvents = () => {
    Object.keys(SCHEMA_EVENTS_MAP).forEach((event) => {
      this.observer.subscribe(event, async (params: {}) => {
        switch (event) {
          case CARHIRE_SEO_METRIC:
          case SEARCH_CLICKED_PAGETYPE_EVENT:
          case CARHIR_AIRPORT_INDICATIVE_PRICE_PANEL_EVENT: {
            await this.funnelLogger.track(SCHEMA_EVENTS_MAP[event], {
              ...params,
            });
            break;
          }
          default:
            break;
        }
      });
    });
  };
}

export default FunnelEventsListener;
