import observer from 'saddlebag-observer';

import {
  namespace,
  EXPERIMENT_CHOKE_POINT_MINIEVENT_NAME,
  EXPERIMENT_CHOKE_POINT_SCHEMA_NAME,
} from './constants';
import {
  AUTOSUGGEST_MINIEVENT_NAME,
  AUTOSUGGEST_TELEMETRY_SCHEMA_NAME,
} from './hotels-utils/skyscanner-application/minievents/autosuggest';

export function logMiniEvent(
  eventName: string,
  schemaName: string,
  message: any,
  fullSchemaName?: string,
): void {
  observer.publish('grappler-track', {
    eventName,
    fullSchemaName: fullSchemaName || `${schemaName}.${eventName}`,
    message,
    isMiniEvent: true,
    consentRequired: true,
    microsite: {
      name: namespace,
    },
  });
}

export function logExperimentChokepoint(experimentName: string): void {
  logMiniEvent(
    EXPERIMENT_CHOKE_POINT_MINIEVENT_NAME,
    EXPERIMENT_CHOKE_POINT_SCHEMA_NAME,
    {
      name: experimentName,
    },
  );
}

export const logAutoSuggestHotelEvent = (message: any): void => {
  logMiniEvent(
    AUTOSUGGEST_MINIEVENT_NAME,
    AUTOSUGGEST_TELEMETRY_SCHEMA_NAME,
    message,
    AUTOSUGGEST_TELEMETRY_SCHEMA_NAME,
  );
};
