// https://skyscanner-prod-sttc-int-eu-west-1.s3.amazonaws.com/sttc/schemadocs/index.html#autosuggest.Telemetry
import { RESULT_TYPE } from './constants';

export const AUTOSUGGEST_TELEMETRY_SCHEMA_NAME = 'autosuggest.TelemetryEvent';
export const AUTOSUGGEST_MINIEVENT_NAME = 'AutosuggestTelemetry';

const buildCultureSettings = (culture) => {
  if (!culture) {
    return {};
  }

  return {
    culture_settings: {
      country: culture.market,
      locale: culture.locale,
      currency: culture.currency,
    },
  };
};

const buildCommonTelemetryProps = (culture) => ({
  ...buildCultureSettings(culture),
  programme: 'HOTELS',
});

export const buildControlsActivatedMessage = (query, culture) => ({
  ...buildCommonTelemetryProps(culture),
  controls_activated: {
    prefilled_query: query.prefilledQuery,
    is_recent_included: query.isRecentIncluded,
  },
});

export const buildResultSelectedMessage = (
  query,
  suggestionValue,
  suggestionIndex,
  entityId,
  culture,
  resultType = RESULT_TYPE.UNSET_RESULT_TYPE,
) => ({
  ...buildCommonTelemetryProps(culture),
  result_selected: {
    query,
    selected_entity_id: entityId,
    selected_value: suggestionValue,
    position: suggestionIndex + 1,
    selected_result_type: resultType,
  },
});

export const buildResultsUpdatedMessage = (query, numResults, culture) => ({
  ...buildCommonTelemetryProps(culture),
  results_updated: {
    query,
    num_results: numResults,
  },
});

export const buildControlsDismissedMessage = (query, culture) => ({
  ...buildCommonTelemetryProps(culture),
  controls_dismissed: {
    query,
  },
});
